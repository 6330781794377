/*
*  BOOKING FORM
*/
.lightbox {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.5);
	z-index: 1;
}
#lightboxwait {
	display: flex;
	align-items: center;
	justify-content: center;
}
#bookingdatesform {
	position: relative;
	padding: 1rem;
	background-color: #fff;
	border-radius: 10px;
	input[readonly] {
		cursor: pointer;
	}
	.button {
		margin: 0;
	}
}
.radio-error.is-visible {
	display: inline-block !important;
}
.content {
	#bkaccordion {
		table {
			font-size: 0.8rem;
		}
		label {
			font-size: 0.8rem;
			//vertical-align: middle;
			margin: 0;
		}
		.radio {
			vertical-align: middle;
			margin: -3px 0.5rem 0 0;
		}
		.checkbox {
			vertical-align: middle;
			margin: 0 0.5rem 0 0;
		}
		.req {
			font-weight: bold;
			color: purple;
		}
		.textauto {
			width: auto;
			display: inline-block;
		}
		.nwrp {
			white-space: nowrap;
		}
		.doublestriped tbody {
			tr:nth-child(2n) {
				background-color: transparent;
			}
			tr:nth-child(4n+3), tr:nth-child(4n+4) {
				background-color: #f1f1f1;
			}
		}
		.person {
			position: relative;
			padding: 1rem;
			background-color: #fff;
			border-radius: 10px;
			margin-bottom: 2rem;
			@include breakpoint(small only) {
				margin-left: -1rem;
				margin-right: -1rem;
			}
			.button {
				margin: 0;
			}
		}
		.skihire {
			position: relative;
			padding: 1rem;
			background-color: #fff;
			border-radius: 10px;
			margin-bottom: 2rem;
			@include breakpoint(small only) {
				margin-left: -1rem;
				margin-right: -1rem;
			}
			.button {
				margin: 0;
			}
		}
		.grid-x > .cell:last-child {
			margin-bottom: 0;
		}
		.accordion-title {
			padding: 0.2rem 1rem;
			background-color: #56bcff;
			border: solid 1px #56bcff;
			color: #fff;
			h4 {
				color: #fff;
			}
			&:hover {
				background-color: #70c8ff;
				text-decoration: none;
			}
			h4 {
				margin: 0;
			}
		}
		.accordion-content {
			border: solid 1px #56bcff;
		}
		.chosen {
			background-color: #ff0;
		}
		p {
			margin: 0;
		}
		th {
			text-align: left;
			padding: 3px 2px 3px 10px;
		}
		td {
			text-align: left;
			padding: 3px 2px 3px 10px;
		}
		th.hd {
			color: #800000;
		}
	}
}
