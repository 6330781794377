//  Variations to Settings
//	----------------------
//
//  Table of Contents:
//
//	0. Palette variables (copyied from incenseaustralia/scr/scss/bizazz-settings)
//	1. Color variables
//		1.1 site variables
//
//	---- end table of contents ---


//	0. Palette variables (copyied from incenseaustralia/scr/scss/bizazz-settings)
// -----------------------------------------------------------------------0
$colours: (
	colour1: (
		name: navy,
		color: #002855,
		background-color: #fff,
		lighterColour: scale-color(#002855, $lightness: +32%),
		veryLightColour: scale-color(#002855, $lightness: 80%),
		contrastColour: color-pick-contrast(#002855),
	),
	colour2: (
		name: yellow,
		color: #ffff00,
		background-color: #fff,
		lighterColour: scale-color(#ffff00, $lightness: +32%),
		veryLightColour: scale-color(#ffff00, $lightness: 80%),
		extraVeryLightColour: scale-color(#ffff00, $lightness: 60%),
		contrastColour: color-pick-contrast(#ffff00),
	),
	red: (
		name: red,
		color: #f00,
		background-color: #fff,
		lighterColour: scale-color(#f00, $lightness: +25%),
		veryLightColour: scale-color(#f00, $lightness: 80%),
		contrastColour: color-pick-contrast(#f00),
		textOnly: true,
	)
);

@function getColour($scheme, $colour) {
    @return map-get(map-get($colours, $scheme), $colour);
}

//	1. Color variables
// -----------------------------------------------------------------------1
$sitehighlight-color: #ff0;
// $sitehighlight-color: #990000;
$sitemenu-color: #fff;
$siteother-color: #28BFEE;
//
// $calloutHover: $lighterColour2;
// $topMenuHoverBg: $colour1;
//
$black1: #000;
$white1: #fff;
// //$defaultAnchorColour: #1779ba;
// //$defaultAnchorHoverColour: #49a9eb;
// $defaultAnchorColour: #1c501a;
// $defaultAnchorHoverColour: scale-color($defaultAnchorColour, $lightness: +25%);

// 1.1 site variables
$foundation-palette: map-merge($foundation-palette, (
  menu-bg: #fff,
  menu-color: #888,
  menu-border: #fff,
  menu-active-bg: $siteother-color,
  menu-active-color: #fff,
  menu-current-bg: #fff,
  menu-current-color: #28BFEE,
  menu-hover-bg: #28bfee,
  menu-hover-color: #fff,
  menu-arrow-color: #888,
  menu-arrow-hover-color: #fff,
  anchor-color: #1779ba,
));
$list-side-margin: 2.25rem;
$anchor-color: get-color(anchor-color);
$anchor-color-hover: scale-color($anchor-color, $lightness: +32%);
$button-background-hover: scale-color($button-background, $lightness: +32%);
$button-background-hover-lightness: 32%;
$card-divider-background: #ff0;
//$card-border: 1px solid $sitehighlight-color;
$drilldown-arrow-color: get-color(menu-arrow-color);
$dropdownmenu-arrow-color: get-color(menu-arrow-color);
$dropdown-menu-item-color-active: get-color(menu-active-color);
$dropdown-menu-item-background-active: get-color(menu-hover-bg);
$globalRadius: 10;
$global-radius: ($globalRadius) * 1px;
$callout-radius: $global-radius;
$card-border-radius: $global-radius;
$button-radius: $global-radius;
$form-button-radius: $global-radius;
$keystroke-radius: $global-radius;
$dropdown-radius: $global-radius;
$label-radius: $global-radius;
$meter-radius: $global-radius;
$pagination-radius: $global-radius;
$progress-radius: $global-radius;
$reveal-radius: $global-radius;
$slider-radius: $global-radius;
$switch-radius: $global-radius;
$switch-paddle-radius: $global-radius;
$thumbnail-radius: $global-radius;
$tooltip-radius: $global-radius;
$menu-item-color-active: get-color(menu-active-color);
$menu-item-background-active: get-color(menu-active-bg);
$topbar-background: get-color(menu-bg);
