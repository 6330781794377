// Bizazz (theshedskihire)
// ------
//
//  Table of Contents:
//
//	1. Accordion
//	2. Badge, Label
//  3. Blockquote
//  4. Buttons
//  5. Callout
//	6. Cards
//	7. Clearfloats
//	8. Content
//      p.error
//      a[name]
//      a:not(.button):not([name]):hover
//	9. div.section
//	10. Error
//	11.	extra paddings and margins
//	12. Figure
//	13. Image
//	14. Light Box
//	15. Lists
//	16. no-js breakpoints
//  17. Orbit
//  18. Quotes
//	19. seolink
//	20. Table
//	21. Tabs
//	22. Text
//	23. Toogle-view
//
//	---- end table of contents ---

//	1.	Accordion
// ---------------------------------------------------------------------------1
.accordion:not(#bkaccordion) {
	.accordion-item:first-child > :first-child {
		border-radius: 0;
	}

	:last-child:not(.is-active) > .accordion-title {
		border-bottom: 1px solid #808080;
		border-radius: 0;
	}

	.accordion-item:last-child > :last-child {
		border-radius: 0;
	}

	.is-active a {
		// background-color: scale-color($colour1, $lightness: +92%);
	}

	&.radius {
		.accordion-item:first-child > :first-child {
			border-radius: 0.6rem 0.6rem 0 0 !important;
		}

		:last-child:not(.is-active) > .accordion-title {
			border-radius: 0 0 0.6rem 0.6rem;
		}

		.accordion-item:last-child > :last-child {
			border-radius: 0 0 0.6rem 0.6rem;
		}
	}
    .accordion-title {
    	font-size: 1rem;
    	padding: 10px 45px 10px 10px;
    	border: 1px solid #808080;
    	// margin-bottom: 5px;
    	background-color: $body-background; // without this line visited accordions show darkgrey on mouse out till next mouse event

    	&:focus {
    		outline: #808080;
    	}
    	@media screen and (max-width: 26em) {
    		font-size: 0.8rem;
    	}

    	h4 {
    		margin-bottom: 0;
    	}

    	p {
    		line-height: 1;
    	}

    	p:last-child {
    		margin-bottom: 0;
    	}

		@each $name, $value in $colours {
			&.#{map-get($value, name)} {
				color: map-get($value, color);
				&:hover {
	    			color: map-get($value, lighterColour);
	    			&:before {
	    				border-color: map-get($value, lighterColour);
	    			}
	    		}
	    		&:focus :not(:hover) {
	    			color: map-get($value, color);
	    		}
	    		&:before {
	    			border: solid map-get($value, color);
	    			border-width: 0 3px 3px 0;
	    		}
			}
		}
    }

    .accordion-title:before {
    	content: '';
    	border-top-width: medium;
    	border-right-width: medium;
    	border-bottom-width: medium;
    	border-left-width: medium;
    	display: inline-block;
    	padding: 6px;
    	-webkit-transform: rotate(45deg);
    	transform: rotate(45deg);
    	position: absolute;
    	right: 2.25rem;
    	top: 45%;
    	@media screen and (max-width: 26em) {
    		right: 1rem;
    	}
    }
    .is-active > .accordion-title::before {
    	content: '';
    	-webkit-transform: rotate(-135deg);
    	transform: rotate(-135deg);
    	top: 55%;
    }
    .accordion-content {
    	border: 1px solid #e6e6e6;
    	margin-bottom: 5px;
    }
}
.bizTabs.accordion {
	@each $name, $value in $colours {
		&.#{map-get($value, name)} {
			.accordion-title {
				color: map-get($value, color);
			}

			.accordion-title:before {
				border: solid map-get($value, color);
				border-width: 0 3px 3px 0;
			}
		}
	}
}

//	2.	Badge, Label
// --------------------------------------------------------------------------2
.badge, .label {
	@each $name, $value in $colours {
		@if not (map-has-key($value, foundation)) {
			&.#{map-get($value, name)} {
				background-color: map-get($value, color);
				color: map-get($value, contrastColour);
			}
		}
	}
}

//	3.	Blockquote
// --------------------------------------------------------------------------3
blockquote {
	margin: 0 0 1rem 1rem;
	padding: 0 1.25rem 0 1.1875rem;

	cite {
		font-size: inherit;
	}

	&.blockquoteBorderHide {
		border-left: none;
	}
}
.hide-blockquote-border {
	border-left: none;
}


//	4.	Buttons
//	--------------------------------------------------------------------------4
.button {
	font-size: 1rem;
}
.button-radius {
	border-radius: $global-radius;
}
.button.round {
	border-radius: 1000px;
}
.button.oval {
	border-radius: 50%;
}
.no-radius {
	border-radius: 0;
}
.button {
	@each $name, $value in $colours {
		@if not (map-has-key($value, foundation)) {
			&.#{map-get($value, name)} {
				background-color: map-get($value, color);
				color: map-get($value, contrastColour);
			}
		}
	}
}

//	5.	Callout
//	-------------------------------------------------------------------------5
.callout-radius {
	border-radius: $global-radius;
}

//	6.	Cards
//	-------------------------------------------------------------------------6
.card {
	position: relative;
	box-shadow: none;

	.card-divider {
		display: block;
	}

	&.shadow {
		-webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
		box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
	}

	&.radius {
		border-radius: $global-radius;
	}

	&.biocard {
		@if map-has-key($colours, colour2) {
  		  border-color: getColour(colour2, color);
  		  .card-divider {
  			  background-color: getColour(colour2, color);
  			  color: color-pick-contrast(getColour(colour2, color));
  		  }
  	  }
  	  .card-section {
  		  background-color: #fff;
  	  }
	}

	@each $name, $value in $colours {
		&.#{map-get($value, name)} {
			border-color: map-get($value, color);
			.card-divider {
				color: map-get($value, contrastColour);
				background-color: map-get($value, color);
				h1, h2, h3, h4, h5, h6 {
			      color: map-get($value, contrastColour) !important;
			  	}
			}
			.card-section {
				background-color: map-get($value, veryLightColour);
			}
		}
	}

	@each $name, $value in $colours {
		&.#{map-get($value, name)} {
			border-color: map-get($value, color);
		}
	}

	.card-label {
		border-style: solid;
		float: right;
		height: 0;
		width: 0;
		position: absolute;
		top: 0;
		right: 0;
		border-width: 0 rem-calc(70) rem-calc(40) 0;
		border-color: rgba(255,255,255,0) $black rgba(255,255,255,0) rgba(255,255,255,0);

		.card-label-text {
			color: $white;
			font-size: 1rem;
			font-weight: bold;
			position: relative;
			right: rem-calc(-32);
			top: -7px;
			white-space: nowrap;
			transform: rotate(30deg);
		}

		@each $name, $value in $colours {
			&.#{map-get($value, name)} {
				border-color: rgba(255,255,255,0) map-get($value, color) rgba(255,255,255,0) rgba(255,255,255,0);
				.card-label-text {
					color: map-get($value, contrastColour);
				}
			}
		}
	}
}

//	7.	Clearfloats
//	-------------------------------------------------------------------------7
.clearfloats {
	clear: both;
}
.clearright {
	clear: right;
}
.clearleft {
	clear: left;
}

//	8.	Content
//	-------------------------------------------------------------------------8
.content {
	p.error {
		font-weight: bold;
		color: #ff0000;
	}
	a[name] {
		color: $black;
		cursor: default;
	}
	a:not(.button):not([name]):hover {
		color: scale-color(get-color(anchor-color), $lightness: +32%);
		text-decoration: underline;
	}
}

//	9.	div.section
//	-------------------------------------------------------------------------9
div.section {
	padding: 1rem 0;
}

//	10.	Error
//	-------------------------------------------------------------------------10
.error {
	font-weight: bold;
	color: #ff0000;
}
#form-error {
	color: #ff0000;
	font-weight: bold;
}

//	11	extra paddings and margins
//	-------------------------------------------------------------------------11
.padding30 {
	padding: 30px;
}
.marginTop30 {
	margin-top: 30px;
}

//	12.	Figure
//	-------------------------------------------------------------------------12
figure {
	margin: 0;
	@media only screen and (max-width: 40em) {
		display: block;
		margin: 0 auto 5px auto;
		max-width: 100%;
		height: auto;
	}
}
figure.captionovertop, figure.captionoverbottom {
	position: relative;
}
figcaption {
	width: 100%;
	text-align: center;
}
figure.captionovertop figcaption {
	position: absolute;
	top: 0px;
	left: 0px;
	background-color: rgba(51, 51, 51, 0.8);
	color: #ffffff;
}
figure.captionoverbottom figcaption {
	position: absolute;
	bottom: 0px;
	left: 0px;
	background-color: rgba(51, 51, 51, 0.8);
	color: #ffffff;
	padding: rem-calc(3 0);
}
figure.imgcentre {
  display: table;
  margin: 0 auto;
}

//	13.	Image
//	-------------------------------------------------------------------------13
.imgborder {
	border: solid 1px #333333;
}
.imgborderwhite {
	background: #ffffff;
	padding: 5px;
	border: solid 1px #e0e0e0;
}
.imgleft {
	float: left;
	margin: 0 10px 5px 0;
	@media only screen and (max-width: 25em) {
		float: none;
		display: block;
		margin: 0 auto 5px auto;
		text-align: center;
	}
}
.imgcentre {
	margin: 0 auto 0 auto;
}
.imgright {
	float: right;
	margin: 0 0 5px 10px;
	@media only screen and (max-width: 40em) {
		float: none;
		display: block;
		margin: 0 auto 5px auto;
		text-align: center;
	}
}
.img-shadow, .show-shadow {
	border: solid 1px #888;
	-moz-box-shadow: 4px 4px 6px 0px #888;
	-webkit-box-shadow: 4px 4px 6px 0px #888;
	box-shadow: 4px 4px 6px 0px #888;
}
.img-radius {
	border-radius: $global-radius;
}

//	14.	Light Box
//	-------------------------------------------------------------------------14
// NOTE: see booking.scss for lightbox scss
/*
.clearing {
	.thumbnail {
		margin: 0 10px 10px 0;
	}
}
#overlay {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: #000000 url(/assets/img/spinner.gif) no-repeat scroll center center;
}
#lightbox {
	position: absolute;
	z-index: 99;
	max-width: 100%;
	border: solid 5px #ffffff;
	img {
		display: block;
	}
}
*/

//	15. Lists
//	-------------------------------------------------------------------------15
ul.disc {
	list-style-type: disc;
}
ul.circle {
	list-style-type: circle;
}
ul.square {
	list-style-type: square;
}
ul.grid-x {
	list-style-type: none;
	margin: 0;
}

//	16.	no-js breakpoints
//	-------------------------------------------------------------------------16
.no-js {
  @include breakpoint(small only) {
    .top-bar {
      display: none;
    }
  }
  @include breakpoint(medium) {
    .title-bar {
      display: none;
    }
  }
}

//	17.	Orbit
//	-------------------------------------------------------------------------17
.orbit-caption {
	background-color: rgba(51,51,51,.8);
	font-size: 1.5rem;
	padding: 5px;
	width: 100%;
	a {
		color: getColour(colour2, color);
		text-decoration: underline;
		&:hover {
			color: getColour(colour2, extraVeryLightColour);
		}
	}
}

//	18.	Quotes
// --------------------------------------------------------------------------18
.quotes {
	&:before {
		content: "\201C";
	}
	&:after {
		content: "\201D";
	}
}
.quotes-single {
	&:before {
		content: "\2018";
	}
	&:after {
		content: "\2019";
	}
}
.quotes-bold {
	&:before {
		content: "\275D";
	}
	&:after {
		content: "\275E";
	}
}
.quotes-french {
	&:before {
		content: "\00AB";
	}
	&:after {
		content: "\00BB";
	}
}

//	19.	seolink
//	-------------------------------------------------------------------------19
a.seolink:link, a.seolink:visited, a.seolink:hover {
    color: #000000;
    text-decoration: none;
}

//	20. Table
//	-------------------------------------------------------------------------20
// $tableInsideBorder: 1px solid #cacaca;
// $tableOutsideBorder: 0;
// table {
// 	font-size: 0.875rem;
// }
// th {
// 	border: $tableInsideBorder;
// }
// td {
// 	border: $tableInsideBorder;
// }
// table tr:first-child td {
// 	border-top: $tableOutsideBorder;
// }
// table tr:last-child td {
// 	border-bottom: $tableOutsideBorder;
// }
// table tr td:first-child,
// table tr th:first-child {
// 	border-left: $tableOutsideBorder;
// }
// table tr td:last-child,
// table tr th:last-child {
// 	border-right: $tableOutsideBorder;
// }

//	21.	Tabs
//	-------------------------------------------------------------------------21
.tabs {
	// was dl.tabs
	margin-left: 5px;
	border: 0;
	.tabs-title {
		// was dd
		margin-left: 5px;
		a {
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
			font-size: 1rem;
			padding: 0.825rem 1.25rem;
			// outline: none;
		}
		@each $name, $value in $colours {
			&.#{map-get($value, name)} {
				> a {
					color: map-get($value, contrastColour);
					background: map-get($value, lighterColour);
					&:hover {
						background: map-get($value, color);
					}
				}
				> a[aria-selected='true'] {
					background: map-get($value, color);
				}
			}
		}
	}
}
.tabs-content .tabs-panel {
	padding: 10px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	@each $name, $value in $colours {
		&.#{map-get($value, name)} {
			border-top: 10px solid map-get($value, color);
			border-left: 1px solid  map-get($value, color);
			border-right: 1px solid  map-get($value, color);
			border-bottom: 1px solid  map-get($value, color);
		}
	}
}

//	22.	Text
//	-------------------------------------------------------------------------22
@each $name, $value in $colours {
	.#{map-get($value, name)}-text-colour {
		color: map-get($value, color);
	}
}
.highlight {
	background: $sitehighlight-color;
}
.normal {
	font-weight: normal;
}

//	23. Toogle-view
//	-------------------------------------------------------------------------23
.toggle-view {
	list-style: none;
	margin: 0;
	padding: 0;
	&>li {
		position: relative;
		margin: 10px;
		border-bottom: solid 1px #ccc;
		cursor: pointer;
		h5, h6 {
			margin: 0 20px 0 0;
			color: get-color(anchor-color);
			&:hover {
				color: scale-color(get-color(anchor-color), $lightness: +32%);
			}
		}
		span {
			position: absolute;
			right: 5px;
			top: 0;
			color: get-color(anchor-color);
			font-weight: bold;
			&:hover {
				color: scale-color(get-color(anchor-color), $lightness: +32%);
			}
		}
		.ans {
			margin:5px 0;
			display:none;
		}
	}
}
