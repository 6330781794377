.top-bar {
	padding: 0;
	li {
		>a {
			background-color: get-color(menu-bg);
			color: get-color(menu-color);
		}
		>a:hover {
			background-color: get-color(menu-hover-bg);
			color: get-color(menu-hover-color);
		}
	}
	li.current {
		>a {
			background-color: get-color(menu-current-bg);
			color: get-color(menu-current-color);
		}
		>a:hover {
			background-color: get-color(menu-hover-bg);
			color: get-color(menu-hover-color);
		}
	}
	.dropdown.menu.medium-horizontal {
		> li.is-dropdown-submenu-parent {
			&.is-active > a::after {
				border-color: get-color(menu-arrow-hover-color) transparent transparent!important;
			}
			&.current > a {
				&::after {
					border-color: get-color(menu-current-color) transparent transparent !important;
				}
				//&:hover::after {
				//	border-color: get-color(menu-arrow-hover-color) transparent transparent !important;
				//}
			}
			&.is-active.current > a {
				&::after {
					border-color: get-color(menu-arrow-hover-color) transparent transparent !important;
				}
			}
		}
	}
	.is-dropdown-submenu {
		.is-dropdown-submenu-parent {
			&.is-active > a::after, &.current > a::after {
				border-color: transparent transparent transparent get-color(menu-arrow-hover-color) !important;
			}
		}
	}
	.drilldown {
		.is-drilldown-submenu-parent {
			&.is-active > a::after, &.current > a::after {
				border-color: transparent transparent transparent get-color(menu-arrow-hover-color) !important;
			}
		}
	}
	.drilldown {
		.is-drilldown-submenu-parent {
			> a:hover::after {
				border-color: transparent transparent transparent get-color(menu-arrow-hover-color) !important;
			}
		}
	}
	.drilldown .js-drilldown-back > a:hover::before {
		border-color: transparent get-color(menu-arrow-hover-color) transparent !important;
	}
}
.menu {
	.menu-text {
		padding: 0;
		a {
			padding: 0.3rem 1rem 0.41rem 1rem;
		}
	}
	a {
		color: get-color(menu-color);
	}
}

.title-bar {
	margin: 0 -0.625rem 0 -0.625rem;
	padding: 0.5rem 0.625rem 0.5rem 0.625rem;
	background: get-color(menu-bg);
	color: get-color(menu-color);
	.title-bar-left a {
		color: #fff;
	}
}
.menu-icon::after {
	background: get-color(menu-color);
	box-shadow: 0 7px 0 get-color(menu-color), 0 14px 0 get-color(menu-color);
}

