// Overide (theshedskihire)
// ------
//
//  Table of Contents:
//
//	1. Body
//	2. Booknow
//	3. Callout / panel
//	4. Card-divider
//	5. Cell
//	6. Content
//	7. Footer
//	8. Header / subheader
//	9.	Icon
//	10. Main
//	11. Menu / sidemenu
//	12. Page
//  13. Person
//	14. Radius
//	??. Reveal
//	15. solve background problem at the end of content
//  16. Text
//
//	---- end table of contents ---

//	1.	Body
// ---------------------------------------------------------------------------1
body {
	background: $white1;
}

//	2.	Booknow
// ---------------------------------------------------------------------------2
#booknow {
	position: fixed;
	right: -50px;
	top: 50%;
	transform: rotate(-90deg);
	background: getColour(colour2, color);
	color: $black1;
	font-weight: bold;
	font-size: 120%;
	padding: 5px 20px;
	border: 1px solid #ffa;
	border-top-left-radius: 25px;
	border-top-right-radius: 25px;
	box-shadow: 0px 0px 10px 0px rgba(#888, 0.5);
	@include breakpoint(small only) {
		display: none;
	}
	&:hover {
		background: #ff8;
	}
}

//	3.	Callout / panel
// ---------------------------------------------------------------------------3
.callout.secondary {
	border: solid 1px #888;
}

//	4.	Card-divider
// ---------------------------------------------------------------------------4
.card-divider {
	h1, h2, h3, h4, h5, h6, p {
		width: 100%;
		color: #000;
	}
}

//	5.	Cell
// ---------------------------------------------------------------------------5
.cell > .panel:first-child {
	margin: 0;
}

//	6.	Content
// ---------------------------------------------------------------------------6
.content {
	p {
		font-size: 1.125rem;
		line-height: 1.7;
		color: #404040;
	}
	li {
		margin-top: 0.7rem;
		color: #404040;
		font-size: 1.125rem;
	}
	h1, h2, h3, h4, h5, h6 {
		color: getColour(colour1, color);
	}
	a:not(.accordion-title):not(.button) {
		text-decoration: underline;
	}
	.seohead {
		color: #ccc;
		font-size: 1rem;
	}
	.size20 {
		font-size: 20px;
	}
}

//	7.	Footer
// ---------------------------------------------------------------------------7
#footer {
	padding: 1rem 1rem 1rem 1rem;
	background: #fff;
	@include breakpoint(small only) {
		margin: 0 -1rem -1rem -1rem;
	}

}

//	8.	Header / subheader
// ---------------------------------------------------------------------------8
#header {
	background-color: #A6A4BC;
	background-repeat: no-repeat;
	background-position: center center;
	background-image: url(../img/banner1980.jpg);
	color: $white1;
	height: 315px;
	h1 {
		font-size: 5rem;
		margin-bottom: -10px;
		text-shadow: 1px 1px 4px #00f;
	}
	h2 {
		font-size: 2.25rem;
		font-style: italic;
		text-shadow: 1px 1px 4px #00f;
	}
	h3 {
		margin-bottom: 0;
	}
	.button {
		font-size: 1.25rem;
		font-weight: bold;
		padding: 0.4em 2em;
		margin-bottom: 0;
		background-color: getColour(colour2, color);
		color: #000;
		box-shadow: 2px 2px 5px 0px rgba(#000, 0.5);
		&:hover {
			background-color: getColour(colour2, extraVeryLightColour);
			// background-color: #ffff66;
			color: #888;
			box-shadow: none;
		}
	}
	@include breakpoint(small only) {
		background-image: url(../img/banner720.jpg);
		height: 115px;
		h1 {
			font-size: 1.5rem;
			margin-bottom: 0px;
		}
		h2 {
			font-size: 1.25rem;
		}
		.button {
			font-size: 0.9rem;
		}
	}
}

#subheader {
	padding: 5px 0 5px 0;
	img {
		margin-left: 5px;
		line-height: 1.2;
		vertical-align: middle;
		&:hover {
			border: solid 1px #fff;
		}
	}
	.phone {
		font-size: 1.5rem;
		margin-left: 10px;
		line-height: 2;
		vertical-align: middle;
		@include breakpoint(small only) {
			font-size: 1.3rem;
		}
	}
	.grid-x > .cell:last-child {
		margin-bottom: 0;
	}
}

//	9.	Icon
// ---------------------------------------------------------------------------9
.icon {
	&:hover {
		border: solid 1px #fff;
	}
}

//	10.	Main
// --------------------------------------------------------------------------10
#main {
	background: #fff;
}

//	11.	Menu / sidemenu
// --------------------------------------------------------------------------11
#menu {
	background: get-color(menu-bg);
	margin: 15px 0 10px 0;
	@include breakpoint(small only) {
		margin: 10px 0 0 0;
	}
	#logo {
		width: 110px;
	}
	.drilldown .is-drilldown-submenu.is-active {
		background: get-color(menu-bg);
	}
	.top-bar {
		.top-bar-right {
			@include breakpoint(medium) {
				margin-top: 30px;
			}
		}
	}
/*
	.top-bar {
		-webkit-box-shadow: 2px 2px 5px 0px rgba(get-color(menu-border), 0.8);
		box-shadow: 2px 2px 5px 0px rgba(get-color(menu-border), 0.8);
		.top-bar-right {
			margin-top: 30px;
		}
	}

	.title-bar {
		-webkit-box-shadow: 0px 4px 5px -5px rgba(get-color(menu-border), 0.8);
		box-shadow: 0px 4px 5px -5px rgba(get-color(menu-border), 0.8);
	}
*/
}

//	12.	Page
// --------------------------------------------------------------------------12
#page {
	margin-top: -10px;
}
.sidemenu {
	&.panel {
		border: none;
	}
	.menu {
		li a:hover {
			color: $anchor-color-hover;
		}
		li.current a {
			color: get-color(menu-current-bg);
		}
	}
}

//	13.	Person
// --------------------------------------------------------------------------13
.person {
	span {
		content: '';
		height: 30px;
		width: 30px;
		display: inline-block;
		background-image: url("../../assets/img/person.svg");
		background-size: 30px 30px;
		vertical-align: -25%;
	}
	&:hover span {
		background-image: url("../../assets/img/person-hover.svg");
	}
}

//	14.	Radius
// --------------------------------------------------------------------------14
.radius {
	border-radius: 5px;
}

//	??.	Reveal
// --------------------------------------------------------------------------??
//body.is-reveal-open {
//	overflow: auto !important;
//	height: auto;
//	position: relative;
//}
//.reveal-overlay {
//	overflow: initial !important;
//	position: absolute;
//}

//	15.	solve background problem at the end of content
// --------------------------------------------------------------------------15
// solve background problem at the end of content
@include breakpoint(small only) {
	.grid-x > .cell:last-child {
		margin-bottom: 1rem;
	}
}

//	16.	Text
// ---------------------------------------------------------------------------16
.green {
	color: $siteother-color;
}
.light-green {
	color: #66a066;
}
.maroon {
	color: $sitehighlight-color;
}
.light-maroon {
	color: #a06666;
}
.grey {
	color: #adadad;
}

.panel {
	border: solid 1px $siteother-color;
	padding: 1rem;
	margin: 1rem 0;
	.panelhead {
		background: $siteother-color;
		color: $white1;
		margin: -1rem -1rem 0 -1rem;
		padding: 1rem;
	}
}
