/*
*  SHOPPING CART
*/
tbody {
	border: none;
	background-color: #fff;
}
td {
	border: none;
}
fieldset {
	border: solid 1px #ddd;
	margin: 1rem 0;
	padding: 0.5rem 1rem 0 1rem;
	legend {
		font-weight: bold;
		margin: 0 0 0 -0.1875rem;
		padding: 0 0.1875rem;
	}
}

#reqshipping {
    margin: 10px auto 0 auto;
    border: solid 1px #cccccc;
    padding: 20px 20px 5px 20px;
    background: #ffffff;
}
#ajaxbasket #reqshipping {
    margin: 0 auto 0 auto;
}
#reqshipping h3 {
    margin: 0 0 20px 0;
}
#reqshipping label {
    display: block;
    float: left;
    width: 150px;
    text-align: right;
    margin: 0 3px 10px 0;
}
#reqshipping input {
    display: block;
    float: left;
    margin: 0 0 10px 0;
}
#reqshipping input.submit {
    display: inline;
    float: none;
    margin: 0;
}
#reqshipping p.footlink {
    clear: both;
    margin: 30px 0 0 0;
    font-size: 88%;
}
.shoppingcart {
    background: #ffffff;
    text-align: center;
}
.shoppingcart h3 {
    margin: 0 0 10px 0;
}
.shoppingcart table {
    margin: 0 auto;
    font-size: 100%;
}
.shoppingcart .costs th {
    background: #B4D9FF;
    padding: 3px 5px 3px 5px;
}
.shoppingcart .costs td {
    //background: #e4e4e4;
    padding: 3px 5px 3px 5px;
}
.shoppingcart .num {
    text-align: right;
}
.shoppingcart .tot {
    background: #B4D9FF;
    text-align: right;
}
.shoppingcart .costs .blank {
    background: #ffffff;
}
.shoppingcart .nav {
    margin: 20px auto 0 auto;
    color: #808000;
    font-weight: bold;
}

