@charset 'utf-8';

@import 'settings';
@import 'bizazz-settings'; // suttonnet - standard bizazz settings
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
// @include foundation-grid;
// @include foundation-flex-grid;
//
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;

// @include foundation-prototype-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

//@import 'font-awesome';
$font-path: "/assets/foundation-icons/foundation-icons";
@font-face {
  font-family: "foundation-icons";
  src: url("#{$font-path}.eot");
  src: url("#{$font-path}.eot?#iefix") format("embedded-opentype"),
       url("#{$font-path}.woff") format("woff"),
       url("#{$font-path}.ttf") format("truetype"),
       url("#{$font-path}.svg#fontcustom") format("svg");
  font-weight: normal;
  font-style: normal;
}
@import '../foundation-icons/foundation-icons.css';

@import 'bizazz';        // suttonnet - standard bizazz overrides
@import 'bizazz-topbar'; // suttonnet - standard bizazz topbar overrides
@import 'bizazzCart'; //  - standard shopping cart settings
@import 'override';      // suttonnet - site overrides
@import 'jquery.datepick';
@import 'booking';       // theshed - site overrides

